<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-start flex-wrap">
        <div>
          <AuctionsFilters @filters-changed="setFilters" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered bg-light">
            <tr class="bg-bb">
              <th>#</th>
              <th>المزاد</th>
              <th>صورة التفويض</th>
              <th>صورة الإستلام</th>
              <th>صورة رئيسية</th>
              <th>تاريخ الإنتهاء</th>
              <th>شركة التأمين</th>
              <th>الموافقة</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="12" class="font-weight-bolder">لا يوجد مزادات</td>
            </tr>
            <tr v-else v-for="item in list" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td><i class="far fa-2x" :class="item.auth_form_count ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i></td>
              <td><i class="far fa-2x" :class="item.receive_form_count ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i></td>
              <td><i class="far fa-2x" :class="item.main_image_count ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i></td>
              <td dir="ltr" v-html="formatDate(item.finish_date)"></td>
              <td dir="ltr">
                {{ !!item.company ? item.company.content : 'غير محدد' }}
              </td>
              <td>
                <i class="far fa-2x" :class="checkY(item.approved) ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
              </td>
              <td>
                <modal-btn v-if="inNeedApproving" :target="`#approve-auction-${item.id}`" btn-class="btn btn-sm btn-primary rounded-0 my-0 mx-1">
                  موافقة
                </modal-btn>
                <modal-btn :target="`#delete_auction_${item.id}`" btn-class="btn btn-sm btn-danger rounded-0 my-0 mx-1">
                  حذف
                </modal-btn>
                <router-link :to="{name: 'auctions.images', params: {auction: item.slug}}" class="btn btn-sm btn-info rounded-0 my-0 mx-1">الذهاب</router-link>
              </td>

              <teleport to="#app">
                <confirm-model
                  :target-id="`approve-auction-${item.id}`"
                  title="موافقة المزاد"
                  confirm-text="موافقة"
                  @confirmed="clickById(`approve-auction-submit-${item.id}`)"
                  @dismissed="resetApprovingErrors">
                  <form @submit.prevent="approve">
                    <input type="hidden" name="auction" :value="item.id">
                    <p class="text-left">هل أنت متأكد من الموافقة على المزاد&nbsp;<span class="link">{{ item.name }}</span>&nbsp;؟</p>
                    <button type="submit" hidden :id="`approve-auction-submit-${item.id}`"></button>
                  </form>
                  <div class="alert alert-danger shadow" v-if="hasApprovingErrors">
                    <p>يرجى التأكد من البيانات التالية للموافقة على المزاد</p>
                    <ol>
                      <li v-for="error in approvingErrors" :key="error">{{ error }}</li>
                    </ol>
                  </div>
                </confirm-model>

                <confirm-model
                  :target-id="`delete_auction_${item.id}`"
                  header-bg="bg-danger"
                  title="حذف المزاد"
                  confirm-btn="btn-danger"
                  cancel-btn="btn-info"
                  @confirmed="deleteAuction(item.id)"
                >
                  <h5>هل أنت متأكد من حذف المزاد ؟</h5>
                </confirm-model>
              </teleport>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!emptyList">
      <div class="col-sm-12">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import ThePaginator from "@/components/UI/ThePaginator";
import AuctionsFilters from "@/components/Auctions/AuctionsFilters";
import ConfirmModel from "@/components/UI/ConfirmModel";
import ModalBtn from "@/components/UI/ModalBtn";
import arrays from "@/common/arrays";
import types from "@/common/types";
import date from "@/common/dateMethods";

export default {
  components: {ModalBtn, ConfirmModel, AuctionsFilters, ThePaginator},
  mixins: [pagination],
  props: {
    apiUrl: {required: true}
  },
  data() {
    return {
      approvingErrors: []
    }
  },
  computed: {
    defaultUrl() {
      return this.apiUrl;
    },
    inNeedApproving() {
      return this.$route.name === 'auctions.need.approving'
    },
    hasApprovingErrors() {
      return !!this.approvingErrors && this.approvingErrors.length > 0
    },
    finishDate() {
      return date.withTime()
    }
  },
  methods: {
    async approve(e) {
      const formData = new FormData(e.target);
      const auction = formData.get('auction');

      if (!!auction) {
        const response = await http.send(`approve-auctions`, formData);
        if (http.responseAccepted(response)) {
          const errors = response.data.errors || ''
          if (!arrays.empty(errors)) {
            this.approvingErrors = errors
          } else {
            await http.popupMessage('success', 'تم تفعيل المزاد');
            await this.hideModal(`#approve-auction-${auction}`);
            this.list = arrays.removeById(this.list, response.data.id)
          }
        }
      }
    },
    checkY(value) {
      return types.checkY(value)
    },
    resetApprovingErrors() {
      this.approvingErrors = []
    },
    async deleteAuction(id) {
      const response = await http.send(`auctions/${id}`, {
        _method: "DELETE",
      });

      if (http.responseAccepted(response)) {
        this.hideModal(`#delete_auction_${id}`)
        this.list = arrays.removeById(this.list, id)
        http.popupMessage("success", "تم حذف المزاد بنجاح");
      }
    },
  },
  async created() {
    await this.setList()
  }
}
</script>